
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>General Ledger Account</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">General Ledger Account</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="GeneralLedgerAccount">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{ name: 'GeneralLedgerAccountList' }"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <div class="form-group required">
                    <label class="control-label">Name</label>
                    <input type="text" id="name" name="name" v-bind:class="{ 'has-error': errors.has('name') }" v-validate="'required'" v-model="value.name" class="form-control name" placeholder="Name" />
                    <div class="help text-danger" v-show="errors.has('name')">
                      {{ errors.first("name") }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label">Description</label>
                    <input type="text" id="description" name="description" v-model="value.description" class="form-control description" placeholder="Description" />
                    <div class="help text-danger" v-show="errors.has('description')">
                      {{ errors.first("description") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Type</label>
                    <select class="form-control" style="width: 100%" v-bind:class="{ 'has-error': errors.has('type') }" v-validate="'required'" name="type" v-model="value.type" id="type">
                      <option value="Asset">Asset</option>
                      <option value="Liability">Liability</option>
                      <option value="Owners-Equity">Owners-Equity</option>
                      <option value="Revenue">Revenue</option>
                      <option value="Expense">Expense</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('type')">
                      {{ errors.first("type") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Normal Balance Type</label>
                    <select class="form-control" style="width: 100%" name="normal_balance_type" v-model="value.normal_balance_type" id="normalBalanceType">
                      <option :value="0">Debit</option>
                      <option :value="1">Credit</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('normal_balance_type')">
                      {{ errors.first('normal_balance_type') }}
                    </div>
                  </div>
                  <input type="hidden" name="createdBy" v-model="value.createdBy" value="value.createdBy" />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">
                Submit
              </button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CBSAccountingApi } from '@/services/api';

export default {
  name: 'GeneralLedgerAccountForm',
  components: {
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {},
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSAccountingApi.updateGeneralLedgerAccount(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'GeneralLedgerAccountList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          } else {
            this.value.created_by = this.profile.id
            CBSAccountingApi.storeGeneralLedgerAccount(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$toaster.success(response.message);
                  this.$router.push({ name: 'GeneralLedgerAccountList' });
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          }
        }
      });
    },
    getDetail: function (id) {
      CBSAccountingApi.showGeneralLedgerAccount(id)
        .then((item) => {
          this.value = item
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id)
      }
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>

